<template>
  <form>
    <InteractivePaymentCard></InteractivePaymentCard>
  </form>
</template>

<script>
import InteractivePaymentCard from "./Interactive-payment-card/InteractivePaymentCard";

export default {
  name: "paymentPage",
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.payment"),
    };
  },
  components: { InteractivePaymentCard },
};
</script>

<style scoped></style>
