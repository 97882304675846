<template>
  <v-container class="mt-7 justify-center d-flex flex-column align-center">
    <div v-if="userPayCardInfo.id">
      <p class="font-weight-medium title text-center">
        {{ $vuetify.lang.t("$vuetify.main.patientDashboard.payment.cardInfo") }}
      </p>
      <p class="text-center">
        {{ $vuetify.lang.t("$vuetify.main.patientDashboard.payment.cardDesk") }}
      </p>
      <v-row class="mb-2" justify="center">
        <v-btn class="mt-2 mb-2" @click="deleteCard">{{
          $vuetify.lang.t("$vuetify.main.patientDashboard.payment.untieCard")
        }}</v-btn>
      </v-row>
    </div>
    <div v-if="!userPayCardInfo.id">
      <div>
        <p class="font-weight-medium title text-center">
          {{
            $vuetify.lang.t(
              "$vuetify.main.patientDashboard.payment.untieCardDesk"
            )
          }}
        </p>
        <p class="text-center">
          {{
            $vuetify.lang.t("$vuetify.main.patientDashboard.payment.bookInfo")
          }}
        </p>
      </div>
      <v-row class="justify-center mt-2">
        <v-btn @click="pay" class="justify-center">{{
          $vuetify.lang.t("$vuetify.main.patientDashboard.payment.connectCard")
        }}</v-btn>

        <v-col cols="12" md="4" offset-md="2" v-if="payCardActivating">
          <v-btn>{{
            $vuetify.lang.t("$vuetify.main.patientDashboard.payment.cancel")
          }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="userPayCardInfo.cardNumber"
      class="card-item"
      :class="{ '-active': isCardFlipped }"
    >
      <div class="card-item__side -front">
        <div
          class="card-item__focus"
          :class="{ '-active': focusElementStyle }"
          :style="focusElementStyle"
          ref="focusElement"
        ></div>
        <div class="card-item__cover">
          <img
            v-if="currentCardBackground"
            :src="currentCardBackground"
            class="card-item__bg"
          />
        </div>
        <div class="card-item__wrapper">
          <div class="card-item__top">
            <img
              src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
              class="card-item__chip"
            />
            <div class="card-item__type">
              <img
                :src="
                  'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' +
                  userPayCardInfo.cardName +
                  '.png'
                "
                v-if="userPayCardInfo.cardName"
                :key="userPayCardInfo.cardName"
                alt
                class="card-item__typeImg"
              />
            </div>
          </div>
          <v-row class="card-item__number justify-center" justify="center">
            <v-row class="card-item__number Item-active ml-4">
              {{ this.parseCardNumber() }}
            </v-row>
          </v-row>
          <div class="card-item__content">
            <label
              :for="userPayCardInfo.cardName"
              class="card-item__info"
              :ref="userPayCardInfo.cardName"
            >
            </label>
            <div class="card-item__date" ref="cardDate"></div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// import CardForm from "./components/CardForm";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "InteractivePaymentCard",
  components: {
    // CardForm,
  },
  data() {
    return {
      focusElementStyle: null,
      currentFocus: null,
      isFocused: false,
      isCardFlipped: false,
      amexCardPlaceholder: "#### ###### #####",
      dinersCardPlaceholder: "#### ###### ####",
      defaultCardPlaceholder: "#### #### #### ####",
      currentPlaceholder: "",

      paySocketChannel: null,
      payCardActivated: false,
      payCardActivating: false,
    };
  },
  watch: {
    // "user.payment_method": {
    //   handler: function () {
    //     this.setUserFromState();
    //   },
    //   deep: true,
    // },
    "callStatus.status": {
      // eslint-disable-next-line no-unused-vars
      handler: function (after, before) {
        this.onStatusChange(after);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    credential() {
      return this.$store.getters["user/getPayCredentials"].data;
    },
    locale() {
      let selectedLocale = this.$store.getters["app/getSelectedLocale"];
      if (selectedLocale === "uk") {
        return "UA";
      } else return selectedLocale;
    },
    currentCardBackground() {
      let random = Math.floor(Math.random() * 25 + 1);
      return `https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/${random}.jpeg`;
    },
    userPayCardInfo() {
      return {
        cardNumber: this.user.payment_method.masked_number,
        cardName: this.user.payment_method.card_type
          ? this.user.payment_method.card_type.toLowerCase()
          : this.user.payment_method.card_type,
        id: this.user.payment_method.id,
      };
    },
  },
  mounted() {
    // this.createPaySocketChannel();
    this.$store.dispatch("user/getProfile");
    // this.setUserFromState();
    // this.renderFormIfCardExist();
    const script = document.createElement("script");
    script.src = `https://secure.wayforpay.com/server/pay-widget.js`;
    script.id = "widget-wfp-script";
    // script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  destroyed() {
    // if (this.paySocketChannel) {
    //   //eslint-disable-next-line no-undef
    //   Echo.leave(this.paySocketChannel.name);
    // }
  },
  methods: {
    parseCardNumber() {
      let firstChunk = this.userPayCardInfo.cardNumber.substring(0, 4);
      let secondChunk = this.userPayCardInfo.cardNumber.substring(4, 6) + "##";
      let thirdChunk = "####";
      let lastChunk = this.userPayCardInfo.cardNumber.substring(10, 14);

      return (
        firstChunk + " " + secondChunk + " " + thirdChunk + " " + lastChunk
      );
    },
    // createPaySocketChannel() {
    //   //eslint-disable-next-line no-undef
    //   this.paySocketChannel = Echo.private(
    //     `patient_payment_methods.${this.user.id}`
    //   );
    //   this.paySocketChannel.listen(".payment_method_saved", (data) => {
    //     let payment_method = data;
    //     this.$store.commit("user/SET_PAYMENT_METHOD", payment_method);
    //   });
    // },
    deleteCard() {
      this.$store.dispatch("user/deleteCard", { id: this.userPayCardInfo.id });
    },
    renderFormIfCardExist() {
      if (this.userPayCardInfo.id) {
        this.payCardActivated = true;
        this.payCardActivating = false;
      } else {
        this.payCardActivated = false;
        this.payCardActivating = true;
      }
    },
    pay() {
      this.$store.dispatch("user/getPaymentCredentials").then(() => {
        let wayforpay = new window.Wayforpay();
        wayforpay.run(
          {
            requestType: "VERIFY",
            straightWidget: true, // чтобы мобилки норм работали
            paymentSystem: this.credential.paymentSystem,
            verifyType: this.credential.verifyType,
            merchantAccount: this.credential.merchantAccount,
            merchantDomainName: this.credential.merchantDomainName,
            authorizationType: this.credential.authorizationType,
            merchantSignature: this.credential.merchantSignature,
            orderReference: this.credential.orderReference,
            amount: this.credential.amount,
            currency: this.credential.currency,
            clientEmail: this.credential.clientEmail,
            clientPhone: this.credential.clientPhone,
            language: this.locale,
            serviceUrl: this.credential.serviceUrl,
          },
          // eslint-disable-next-line no-unused-vars
          function (response) {
            // console.log(response, "on approved");
            store.dispatch("user/getProfile");
          },
          // eslint-disable-next-line no-unused-vars
          function (response) {
            // console.log(response, "on declined");
          },
          // eslint-disable-next-line no-unused-vars
          function (response) {
            // console.log(response, "on pending or in processing");
          }
        );
      });
    },
    sendPaymentCredentials(payload) {
      this.$store.dispatch("user/sendPaymentCredentials", payload);
    },
    // setUserFromState() {
    //   this.userPayCardInfo.cardNumber = this.user.payment_method.masked_number;
    //   this.userPayCardInfo.cardName = this.user.payment_method.card_type;
    //   this.userPayCardInfo.id = this.user.payment_method.id;
    //   if (this.user.payment_method.card_type) {
    //     this.userPayCardInfo.cardName =
    //       this.userPayCardInfo.cardName.toLowerCase();
    //   }
    // },

    async anotherCardActivating(id) {
      await this.$store.dispatch("user/deleteCard", { id: id });
      await this.$store.dispatch("user/getProfile", {});
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/styles/interactive-payment-card.scss";
</style>
